import React from "react";
import { Link } from "gatsby";
import github from "../img/github-icon.svg";
import { FaGripLinesVertical, FaMapPin, FaPhoneAlt } from "react-icons/fa";

const Topbar = class extends React.Component {
  render() {
    return (
      <div className="topbar">
        <div
          className="container column is-vcentered"
          style={{ display: "flex" }}
        >
          <span>
            <FaMapPin size={15} />
          </span>
          <div style={{ marginLeft: "5px" }}>
            <h3>Unit H, 49 Landor Street, Birmingham, B8 1AE</h3>
          </div>
          <span>
            <FaGripLinesVertical size={25} />
          </span>

          <span>
            <FaPhoneAlt size={13} />
          </span>
          <div style={{ marginLeft: "10px" }}>
          <h3>0121 822 8588</h3>
          </div>
        </div>
      </div>
    );
  }
};

export default Topbar;
